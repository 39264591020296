import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [4],
		"/auth": [5,[2]],
		"/auth/email-verify-request": [6,[2]],
		"/my-page": [~7,[3]],
		"/my-page/checkout-return": [~9,[3]],
		"/my-page/checkout": [8,[3]],
		"/my-page/order": [10,[3]],
		"/my-page/service/[id]": [~11,[3]],
		"/my-page/subscription": [~12,[3]],
		"/my-page/subscription/[id]": [~13,[3]],
		"/panel-account": [~14],
		"/panel-account/password": [15],
		"/servers": [16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';