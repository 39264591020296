import * as Sentry from "@sentry/sveltekit";
import {handleErrorWithSentry, replayIntegration} from "@sentry/sveltekit";
import {PUBLIC_ENVIRONMENT} from "$env/static/public";

Sentry.init({
    dsn: 'https://0febdb4213e83615cbea9380072b60b7@o4506865106747392.ingest.us.sentry.io/4506865113628672',
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],

    environment: PUBLIC_ENVIRONMENT
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
